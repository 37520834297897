document.addEventListener("DOMContentLoaded", function () {
	$(document).ready(function () {
        const path = window.location.pathname;
        // set default value list filters
        setTimeout(() => {
            if (path === "/delivery-note/list") {
                $('.web-list-filters select[data-fieldname="workflow_state"]').val("Yêu cầu xuất hàng").trigger("change");
                // Add workflow button
                $btn_barcode_list = $(`<button class="btn btn-default btn-barcode-list btn-sm ml-2">
                    ${__("Barcode")}
                </button>`);
                $(".web-list-header .web-list-actions").append($btn_barcode_list);
                $(".btn-barcode-list").on('click', () => {
                    new frappe.ui.Scanner({
                        dialog: true,
                        multiple: false,
                        on_scan(data) {
                            let scan_barcode = data.decodedText;
                            if (scan_barcode) {
                                frappe.call({
                                    method: "remak.utils.utils.open_doc_by_barcode",
                                    args: {
                                        barcode: scan_barcode
                                    },
                                    callback: function(r) {
                                        if (r.message!= ""){
                                            window.location.href = r.message;
                                        }else{
                                            frappe.msgprint(__("Not found"));
                                        }
                                        
                                    }
                                });
                            }
                        }
                    });
                });
            }
        }, 500);
	});
});